import React from "react"
import { PageProps } from "gatsby"
import { styled } from "../../stitches.config"
import { PageLayout } from "../layouts"
import { Paragraph, Meta, ShareButtons, GrayContainer } from "../components"
import { SEO } from "../components/Seo/Seo"


const About: React.FC<PageProps> = () => (
  <PageLayout>
    <GrayContainer >
      <Meta title="About" />
      <Paragraph>
        Since 2016 as a Professional developer spanning across several sectors such as {NelogicaLink}, prop quantitative trading firm, {ElysiosLink} and {AxurLink}.
        <br />Worked on different sized companies from startups (10- people), mid sized (100+ people) to large companies.
        <br />Programming since 2007 at school for the {RoboticTeamLink} and later for university's computer graphics group (2013–2016) as research intern.
        <br /><br />
        I hold Bachelor’s degree in Computer Engineering from the {UfrgsLink}, which is ranked among the top 10 universities in Latin America.
        <br />
        As a former engineer, I have a strong passion for mathematics, I attended twice as the calculus tutor for the university back on 2012 and 2013.
        <br /><br />
        I am specialized in Amazon Web Services as an associate level AWS Certified Developer, also experienced in Docker, Kubernetes, Python and Java microservices, I've been using Linux as my primary OS for over 7 years.
        <br /><br />
        Adept of Uncle Bob clean code school and TDD as best practices and most of my personal cloud structure is built on AWS and using Infrastructure as code (cloudformation).

        {/* What I value?
          - Data driven
          - Brutal honesty, so problems can be found and fixed sooner
          - Make a section about coffee */}
      </Paragraph>
    </GrayContainer>
    <ShareButtons />
  </PageLayout>
)

const UfrgsLink = <a href="https://en.wikipedia.org/wiki/Federal_University_of_Rio_Grande_do_Sul"><u style="color:#CCCCCC">Federal University of Rio Grande do Sul</u></a>
const RoboticTeamLink = <a href="https://www-opolvo-com-br.translate.goog/noticias/59021/Um-rob%C3%B4-pode-fazer-toda-a-diferen%C3%A7a.html?_x_tr_sch=http&_x_tr_sl=pt&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=wapp"><u style="color:#CCCCCC">robotics team</u></a>
const NelogicaLink = <a href="https://www.nelogica.com.br/"><u style="color:#CCCCCC">desktop trading platform</u></a>
const AxurLink = <a href="https://www.axur.com/"><u style="color:#CCCCCC">cybersecurity</u></a>
const ElysiosLink = <a href="https://elysios-com-br.translate.goog/?_x_tr_sl=pt&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=wapp"><u style="color:#CCCCCC">agricultural IoT</u></a>

export default About

export const Head = () => (
  <SEO title="about" pathname="/about"/>
)
